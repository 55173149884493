import React from "react";
import PageParallax from "./page-parallax";
import BlockContent from "./block-content";
import {GatsbyImage} from "gatsby-plugin-image";
import ResponsiveEmbed from "react-responsive-embed";
import {InstagramEmbed, TikTokEmbed} from 'react-social-media-embed';

function Project(props) {
    const {_rawBody, title, client, heroImage, mainImage, media} = props;

    return (
        <>
            <PageParallax image={heroImage ? heroImage : mainImage}/>
            <section id="project-info">
                <div
                    className="container mx-auto relative pb-3 pt-5 px-5 block-content bg-secondary lg:bg-white lg:-mt-44 lg:mb-10 lg:shadow-lg">
                    <div className="grid lg:grid-cols-2 gap-y-2 lg:gap-x-8 lg:gap-y-0">

                        <div className="order-2 lg:order-1">
                            {media.map((node, i) => (
                                <React.Fragment key={i}>
                                    {node.mediaType === "image" && node.mediaImage && <div key={i} className="pb-2">
                                        <GatsbyImage
                                            key={i}
                                            image={node.mediaImage.asset.gatsbyImageData}
                                            alt="Project Image"/></div>}

                                    {node.mediaType === "youtube" && node.mediaYoutube && <div key={i} className="pb-2">
                                        <ResponsiveEmbed
                                            allowFullScreen
                                            src={node.mediaYoutube.url}
                                            ratio={node.videoRatio
                                            ? `${node.videoRatio}`
                                            : '16:9'}/></div>}

                                    {node.mediaType === "vimeo" && node.mediaVimeo && <div key={i} className="pb-2">
                                        <ResponsiveEmbed
                                            allowFullScreen
                                            src={node.mediaVimeo.url}
                                            ratio={node.videoRatio
                                            ? `${node.videoRatio}`
                                            : '16:9'}/></div>}

                                    {node.mediaType === "instagram" && node.mediaInstagram && <div key={i} className="flex justify-center pb-2">
                                        <InstagramEmbed url={node.mediaInstagram.url} width="100%"/>
                                        </div>}

                                    {node.mediaType === "tiktok" && node.mediaTikTok && <div key={i} className="flex justify-center pb-2">
                                        <TikTokEmbed url={node.mediaTikTok.url} width={325}/></div>}

                                    {node.mediaType === "other" && node.mediaOther && <div key={i} className="pb-2">
                                        <ResponsiveEmbed
                                            allowFullScreen
                                            src={node.mediaOther.url}
                                            ratio={node.videoRatio
                                            ? `${node.videoRatio}`
                                            : '16:9'}/></div>}
                                </React.Fragment>
                            ))}
                        </div>

                        <div className="order-1 lg:order-2">
                            {_rawBody && <>
                                <div className="lg:sticky lg:top-36">
                                    <h2>{title}</h2>
                                    <h3 className="uppercase">{client.clientName}</h3><div className="mt-3"><BlockContent blocks={_rawBody || []}/></div></div>
                            </>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Project;