import React from "react";
import { graphql } from "gatsby";
import Project from "../components/project";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import PrevNext from "../components/prevnext";

export const query = graphql`
query ProjectTemplateQuery($id: String!) {
    sampleProject: sanityWork(id: { eq: $id }) {
      id
      publishedAt
      mainImage {
        ...Image
      }
      heroImage {
        ...Image
      }
      title
      client {
        clientName
      }
      slug {
        current
      }
      _rawBody
      media {
        mediaType
        mediaImage {
          asset {
            gatsbyImageData
          }
        }
        mediaYoutube {
          url
        }
        mediaVimeo {
          url
        }
        mediaInstagram {
          url
        }
        mediaTikTok {
          url
        }
        mediaOther {
          url
        }
        videoRatio
      }
    }
  }  
`;

const ProjectTemplate = (props) => {
  const {data} = props;
  const project = data && data.sampleProject;
  const pagination = props.pageContext;

  return (
    <Layout>
      {project && <SEO title={project.title || "Untitled"} image={project.heroImage || project.mainImage}/>}
      {project && <><Project {...project} /><PrevNext {...pagination} category="work"/></>}
    </Layout>
  );
};

export default ProjectTemplate;
